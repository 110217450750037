import React from 'react';

export default class Video extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      switch: true,
    }
  }

  render() {
    return (
      <div style={{height: 'auto' }}>
        <iframe 
          src='https://www.youtube.com/embed/xapEwCh-9Zc'
          frameborder='0'
          allow='autoplay; encrypted-media'
          allowfullscreen
          title='video'
          width='100%'
          height='280'
        />
      </div>
    )
  }
}