import React from 'react';
import ParticleComponent from './ParticleComponent.jsx';
import './ParticleComponent.css';
import { createGlobalStyle, ThemeProvider } from 'styled-components';
import { styleReset, List, ListItem, Divider,
  Window,
  WindowContent,
  WindowHeader,
  Button,
  Toolbar,
  Panel } from 'react95';
// pick a theme of your choice
import original from "react95/dist/themes/original";
// original Windows95 font (optionally)
import ms_sans_serif from "react95/dist/fonts/ms_sans_serif.woff2";
import ms_sans_serif_bold from "react95/dist/fonts/ms_sans_serif_bold.woff2";

// import logo from './moon.jpeg';
import logo from './mason.png';
import './App.css';
import Video from './Video.jsx';

const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif}') format('woff2');
    font-weight: 400;
    font-style: normal
  }
  @font-face {
    font-family: 'ms_sans_serif';
    src: url('${ms_sans_serif_bold}') format('woff2');
    font-weight: bold;
    font-style: normal
  }
  body {
    font-family: 'ms_sans_serif';
  }
  ${styleReset}
`;



function App() {
  return (
    <div className="App">
      
      <GlobalStyles />
        <ThemeProvider theme={original}>
          <ParticleComponent />
          <header className="App-header">
            <img src={logo} className="App-logo" alt="logo" />
            <Window resizable className='windowsYo'>
      <WindowHeader className='window-header'>
        <span>✈️ drone</span>
        {/* <Button>
          <span className='close-icon' />
        </Button> */}
      </WindowHeader>
      <Toolbar>
        <Button variant='menu' size='sm'>
          File
        </Button>
        <Button variant='menu' size='sm'>
          Edit
        </Button>
        <Button variant='menu' size='sm' disabled>
          Save
        </Button>
      </Toolbar>
      <WindowContent>
        <Video/>
      </WindowContent>
      {/* <Panel variant='well' className='footer'>
        Put some useful informations here
      </Panel> */}
    </Window>
            
          </header>
        </ThemeProvider>
    </div>
  );
}

export default App;
